import { Component, Prop, Host, h, Event, EventEmitter } from '@stencil/core'
import { PTZCardBadgeTypes } from './types/ptz-selector.types'
import { PTZSelectorConfig } from './types/ptz-selector.enums'

@Component({
  tag: `ptz-selector`,
  styleUrl: 'ptz-selector.scss',
  shadow: false,
})

export class PTZCardBadge {
  /** Id do badge */
  @Prop() badgeId: string

  /** SKU do badge */
  @Prop() badgeSku?: string

  /** Texto do badge */
  @Prop() text: string

  /** Badge está selecionado */
  @Prop() selected: boolean

  /** Title de acessibilidade */
  @Prop() description: string

  /** Estado de renderização */
  @Prop() skeleton?: boolean = false

  /** Modo de apenas leitura */
  @Prop() readOnly?: boolean = false

  /** Badge está desabilitado ao clique */
  @Prop() view?: boolean = false

  /** Tamanho do badge */
  @Prop() size: PTZCardBadgeTypes.Size

  /** Tipo de badge */
  @Prop() kind?: PTZCardBadgeTypes.Kind = PTZSelectorConfig.Kind.Default

  /** Evento que dispara no click do botão */
  @Event({
    eventName: 'clickBadge',
    composed: true,
  })
  clickBadge: EventEmitter<PTZCardBadgeTypes.ClickEvent>

  private handleClick(event: MouseEvent) {
    event.preventDefault()
    this.clickBadge.emit({ event, id: this.badgeId, sku: this.badgeSku, kind: this.kind })
  }

  render() {
    const skeleton = {
      sm: { w: 60, h: 24 },
      md: { w: 60, h: 32 },
      lg: { w: 90, h: 56 }
    }
    const kind = {
      isSale: this.kind === PTZSelectorConfig.Kind.Sale,
      isCombo: this.kind === PTZSelectorConfig.Kind.Combo,
      isUnavailable: this.kind === PTZSelectorConfig.Kind.Unavailable
    }
    const isLgSize = this.size === PTZSelectorConfig.Size.SizeLg

    const classCardBadge = {
      'ptz-selector': true,
      'ptz-selector-combo': kind.isCombo,
      'ptz-selector-selected': this.selected,
      'ptz-selector-sale': kind.isSale && !this.view,
      'ptz-selector-unavailable': kind.isUnavailable && !this.view,
      'ptz-selector-enabled': !this.view && !this.selected,
      'ptz-selector-sm': this.size === PTZSelectorConfig.Size.SizeSm,
      'ptz-selector-md': this.size === PTZSelectorConfig.Size.SizeMd,
      'ptz-selector-lg': isLgSize,
      'ptz-selector-view': this.view && !this.selected && !isLgSize,
    }

    const renderComboTopIcon =
      isLgSize && kind.isCombo ? (
        <div class={`ptz-selector-combo-top-icon ptz-selector-${this.size}-combo-top-icon`}>
          <ptz-icon name="grin-tongue-wink" color="neutral-black" variant="line" size="md" />
        </div>
      ) : (
        ''
      )

    const renderSaleIcon =
      kind.isSale && !this.view ? (
        <div class={`ptz-selector-sale-icon ptz-selector-${this.size}-sale-icon`}>
          {isLgSize ?
            <ptz-icon name="percent" color="neutral-darker-accent" variant="line" size="md" /> :
            <ptz-icon name="percent" color="neutral-darker-accent" variant="line" size="3xs" />}
        </div>
      ) : (
        ''
      )

    const renderUnavailableIcon =
      kind.isUnavailable && !this.view ? (
        <div class={`ptz-selector-unavailable-icon ptz-selector-${this.size}-unavailable-icon`}>
          {isLgSize ?
            <ptz-icon name="petz-notification" color="neutral-darker-accent" variant="line" size="md" /> :
            <ptz-icon name="petz-notification" color="neutral-darker-accent" variant="line" size="3xs" />}
        </div>
      ) : (
        ''
      )

    const comboColor = () => {
      if (kind.isUnavailable && !this.selected) {
        return "neutral-mid"
      }
      if (this.selected) {
        return "neutral-white"
      }
      if (this.view) {
        return "neutral-dark"
      }
      return "neutral-darker-accent"
    }

    return (
      <Host class={{'ptz-selector-read-only': this.readOnly}}>
        {this.skeleton ?
          <ptz-skeleton width={skeleton[this.size].w} height={skeleton[this.size].h} rounded="md">
            <slot name='support-text' />
          </ptz-skeleton> :
          <button
            class={classCardBadge}
            title={this.description}
            disabled={this.view}
            onClick={event => this.readOnly ? null : this.handleClick(event)}
          >
            {renderSaleIcon}
            {renderComboTopIcon}
            {renderUnavailableIcon}
            <div class={"ptz-selector-wrapper"}>
              {!isLgSize && !this.view && kind.isCombo ? (
                <div class={'ptz-selector-combo-icon'}>
                  <ptz-icon name="grin-tongue-wink" color={comboColor()} variant="line" size="2xs"></ptz-icon>
                </div>
              ) : (
                ''
              )}
              {this.text}
            </div>
            <div class={`ptz-selector-${this.size}-support-text`}>
              <slot name='support-text' />
            </div>
          </button>
        }
      </Host>
    )
  }
}
