$ptzSpacingSizes: (
  '4xs': $petz-spacing-4xs,
  '3xs': $petz-spacing-3xs,
  '2xs': $petz-spacing-2xs,
  'xs': $petz-spacing-xs,
  'sm': $petz-spacing-sm,
  'md': $petz-spacing-md,
  'lg': $petz-spacing-lg,
  'xl': $petz-spacing-xl,
  '2xl': $petz-spacing-2xl,
  '3xl': $petz-spacing-3xl,
  '4xl': $petz-spacing-4xl,
  '5xl': $petz-spacing-5xl,
  '6xl': $petz-spacing-6xl,
  '7xl': $petz-spacing-7xl,
  '8xl': $petz-spacing-8xl,
  'none': 0
);

ptz-carousel {
  box-sizing: border-box;

  button {
    all: unset;
    cursor: pointer;
  }

  .ptz-carousel {
    width: 100%;
    position: relative;

    &-slider {
      display: flex;
      flex-flow: row nowrap;
      overflow-x: auto;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      &-fit {
        overflow: hidden;
        .ptz-carousel-slide {
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        }
        .slide-show {
          opacity: 1;
        }
      }

      @each $id, $size in $ptzSpacingSizes {
        &-#{$id} {
          gap: $size;
        }
      }
    }

    &-slider::-webkit-scrollbar {
      display: none;
    }

    &-arrow-left,
    &-arrow-right {
      top: 50%;
      z-index: 2;
      position: absolute;
      transform: translateY(-50%);
    }

    &-arrow-left {
      right: auto;
      left: 0;
    }

    &-arrow-right {
      right: 0;
      left: auto;
    }

    .hidden-button {
      display: none;
    }

    .disabled-button {
      cursor: default;
    }
  }
}