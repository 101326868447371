import PTZTokens from '@petz/ds-tokens/petz';
import { Component, Prop, Host, h, State, Watch, Element } from '@stencil/core';
import { PTZCarouselTypes } from './types/ptz-carousel.types';

@Component({
  tag: `ptz-carousel`,
  styleUrl: 'ptz-carousel.scss',
  shadow: false,
})
export class PTZCarousel {
  /** Opção que desabilita botões no primeiro e último slide */
  @Prop() disabledButtons?: boolean = false;

  /** Opção que esconde os botões no primeiro e último slide */
  @Prop() hiddenButtons?: boolean = false;

  /** Margin entre os items do carousel */
  @Prop() slideSpacing: PTZCarouselTypes.SlideSpacing;

  /** Tipo de scroll ao clicar na seta */
  @Prop() scrollMove?: PTZCarouselTypes.ScrollMove = 'oneByOne';

  /** Carrossel do tipo que ajusta os slides no espaço determinado, sem exibir cortado */
  @Prop() preventSlideCutting?: boolean = false;

  /** Indica se está no final do carrossel */
  @State() finishOfSlider = false;

  /** Indica se está no inicio do carrossel */
  @State() startOfSlider = true;

  /** Index do slide atual */
  @State() currentSlideIndex = 0;

  /** Indica em que momento está do scroll */
  @State() currentScroll = 0;

  /** A versão da tela é mobile ou desktop */
  @State() isMobile: boolean = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`).matches;

  /** Informação sobre a media query */
  @State() matchMedia: MediaQueryList;

  /** Estado desabilitado do botão esquerdo no primeiro slide */
  @State() disabledLeftButton: boolean = this.disabledButtons;

  /** Estado desabilitado do botão direito no último slide */
  @State() disabledRightButton: boolean = false;

  /** Estado esconde o botão esquerdo no primeiro slide */
  @State() hiddenLeftButton: boolean = this.hiddenButtons;

  /** Estado esconde o botão direito no último slide */
  @State() hiddenRightButton: boolean = false;

  /** Margin entre os items do carousel automático */
  @State() autoSlideSpacing: number = 0;

  /** Quantidade de slides visíveis */
  @State() amountSlidesToShow: number;

  /** Largura total do container */
  @State() containerWidth: number;

  /** Largura slide */
  @State() slideWidth: number;

  /** Largura do gap */
  @State() gapWidth: number;

  /** quantidade de gaps */
  @State() gapsAmount: number;

  @Element() el: HTMLElement;

  /** Referência do container dos slides */
  private slidesContainer: HTMLElement;

  private restartCarouselFromStartPosition() {
    this.currentScroll = 0;
  }

  private handleMatchMediaChange() {
    this.isMobile = this.matchMedia.matches;
  }

  private scrollSlideOneToOne = (side: PTZCarouselTypes.Side) => {
    const items = document.querySelectorAll('.ptz-carousel-slide');
    const currentSlide = items[this.currentSlideIndex] as HTMLElement;
    const slideWidth = currentSlide.offsetWidth;

    if (side === 'previous') {
      if (this.currentSlideIndex > 0) {
        this.currentSlideIndex--;
      }
      this.currentScroll -= slideWidth;
    }
    if (side === 'next') {
      if (this.currentSlideIndex < items.length - 1) {
        this.currentSlideIndex++;
      }
      this.currentScroll += slideWidth;
    }
    this.handleUpdateVisibleSlides();
  };

  private getCarouselValues() {
    const containerElement = document.querySelector('.ptz-carousel-slider') as HTMLElement;
    const firstSlideElement = document.querySelectorAll('.ptz-carousel-slide')[0] as HTMLElement;
    const containerWidth = containerElement?.offsetWidth;
    const slideWidth = firstSlideElement?.offsetWidth;
    const gapValue = getComputedStyle(this.slidesContainer).getPropertyValue('gap');
    const fitsSlideOnContainer = containerWidth / (slideWidth + parseFloat(gapValue));

    this.containerWidth = containerWidth;
    this.slideWidth = slideWidth;
    this.gapsAmount = Math.floor(fitsSlideOnContainer);
    this.gapWidth = parseFloat(gapValue);
  };

  private scrollSlidePercent = (side: PTZCarouselTypes.Side, percentage: number) => {
    let moveWidth = this.slidesContainer.clientWidth * (percentage / 100);
    
    if (percentage === 100) {
      const slidesTotalWidth = this.slideWidth * this.gapsAmount;
      const gapsTotalWidth = this.gapWidth * this.gapsAmount;
      moveWidth = slidesTotalWidth + gapsTotalWidth;
    }

    if (side === 'previous') {
      this.currentScroll -= moveWidth;
    }
    if (side === 'next') {
      this.currentScroll += moveWidth;
    }
    this.handleUpdateVisibleSlides();
  };

  private updateSlidePosition = (side: PTZCarouselTypes.Side) => {
    if (this.scrollMove === 'oneByOne') {
      this.scrollSlideOneToOne(side);
      this.handleUpdateVisibleSlides();
    }
    if (this.scrollMove === 'full') {
      this.scrollSlidePercent(side, 100);
    }
    if (this.scrollMove === 'half') {
      this.scrollSlidePercent(side, 50);
    }

    this.slidesContainer.scrollLeft = this.currentScroll;
  };

  private handleNextSlide = event => {
    event.preventDefault();
    if (this.finishOfSlider) return;
    this.updateSlidePosition('next');
  };

  private handlePreviousSlide = event => {
    event.preventDefault();
    if (this.startOfSlider) return;
    this.updateSlidePosition('previous');
  };

  private applyStyleToSlides = (items: HTMLCollection) => {
    Array.from(items).forEach((item: HTMLElement) => {
      item.classList.add('ptz-carousel-slide');
    });
  };

  private handleCalculateCarouselSpacing = () => {
    const containerElement = document.querySelector('.ptz-carousel-slider') as HTMLElement;
    const firstSlideElement = document.querySelectorAll('.ptz-carousel-slide')[0] as HTMLElement;
    this.containerWidth = containerElement?.offsetWidth;
    const slideWidth = firstSlideElement?.offsetWidth;
    const amountOfSlidesThatFitInContainer = Math.floor(this.containerWidth / slideWidth);
    const totalSpaceBetweenSlides = this.containerWidth - slideWidth * amountOfSlidesThatFitInContainer;
    const hasMinimumSpaceBetweenSlides = totalSpaceBetweenSlides >= (amountOfSlidesThatFitInContainer - 1) * 8;
    this.amountSlidesToShow = hasMinimumSpaceBetweenSlides ? amountOfSlidesThatFitInContainer : amountOfSlidesThatFitInContainer - 1;
    const updatedTotalSpaceBetweenSlides = this.containerWidth - slideWidth * this.amountSlidesToShow;
    this.autoSlideSpacing = updatedTotalSpaceBetweenSlides / (this.amountSlidesToShow - 1);
    this.handleUpdateVisibleSlides();
  };

  private handleUpdateVisibleSlides = () => {
    const carouselSlides = document.querySelectorAll('.ptz-carousel-slide');
    carouselSlides.forEach((slide: HTMLElement) => {
      slide.classList?.remove('slide-show');
      if (slide.style) {
        slide.style.marginRight = '';
        slide.style.marginLeft = '';
      }
    });

    for (let visibleIndex = 0; visibleIndex < this.amountSlidesToShow; visibleIndex++) {
      const index = (this.currentSlideIndex + visibleIndex) % carouselSlides.length;
      const slide = carouselSlides[index] as HTMLElement;
      slide.classList.add('slide-show');
      slide.style.marginRight = `${this.autoSlideSpacing}px`;
      if (visibleIndex === this.amountSlidesToShow - 1) {
        slide.style.marginRight = '';
      }
      if (index === carouselSlides.length - 1) {
        this.disabledRightButton = true;
      }
      if (index === 0) {
        this.disabledLeftButton = true;
      }
    }
  };

  @Watch('currentScroll')
  watchCurrentScroll() {
    this.startOfSlider = this.currentScroll <= 0;
    this.finishOfSlider =
      this.scrollMove === 'full'
        ? this.currentScroll >= this.slidesContainer.scrollWidth - this.slidesContainer.clientWidth
        : this.currentScroll > this.slidesContainer.scrollWidth - this.slidesContainer.clientWidth;

    this.disabledLeftButton = this.disabledButtons && this.startOfSlider;
    this.disabledRightButton = this.disabledButtons && this.finishOfSlider;
    this.hiddenLeftButton = this.hiddenButtons && this.startOfSlider;
    this.hiddenRightButton = this.hiddenButtons && this.finishOfSlider;
  }

  private toggleChildSliderDisabled(parentClass: string, disabledValue: string) {
    const parentButton = this.el.querySelector(parentClass);

    const childSlider = parentButton?.firstElementChild;

    if (childSlider) {
      childSlider.setAttribute('disabled', disabledValue);
    }
  }

  @Watch('disabledLeftButton')
  @Watch('disabledRightButton')
  watchDisabledButton() {
    if (this.disabledButtons) {
      this.toggleChildSliderDisabled('.ptz-carousel-arrow-left.disabled-button', this.disabledLeftButton.toString());

      this.toggleChildSliderDisabled('.ptz-carousel-arrow-right.disabled-button', this.disabledRightButton.toString());
    }
  }

  componentWillLoad() {
    this.matchMedia = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`);
    this.matchMedia.onchange = this.handleMatchMediaChange.bind(this);
  }

  componentDidLoad() {
    window.addEventListener('resize', () => {
      this.getCarouselValues();
      this.restartCarouselFromStartPosition();
    });
  }

  disconnectedCallback() {
    this.matchMedia.onchange = null;
    window.removeEventListener('resize', () => {
      this.getCarouselValues();
      this.restartCarouselFromStartPosition();
    });
  }

  componentDidRender() {
    this.watchDisabledButton();
    const slides = document.querySelector('.ptz-carousel-slider')?.children;

    if (this.preventSlideCutting && this.slideSpacing === 'none') {
      this.handleCalculateCarouselSpacing();
    }

    if (slides) {
      this.applyStyleToSlides(slides);
    }

    const hasHorizontalScrollbar = this.slidesContainer.scrollWidth > this.slidesContainer.clientWidth;

    if (!hasHorizontalScrollbar) {
      this.hiddenLeftButton = true;
      this.hiddenRightButton = true;
    }

    this.getCarouselValues();
  }

  render() {
    const classArrowButton = side => {
      return {
        [`ptz-carousel-arrow-${side}`]: true,
        'disabled-button': side === 'left' ? this.disabledLeftButton : this.disabledRightButton,
        'hidden-button': side === 'left' ? this.hiddenLeftButton : this.hiddenRightButton,
      };
    };

    return (
      <Host>
        <div class={'ptz-carousel'}>
          <div
            class={`ptz-carousel-slider ptz-carousel-slider-${this.slideSpacing} ${this.preventSlideCutting ? 'ptz-carousel-slider-fit' : ''}`}
            ref={el => (this.slidesContainer = el)}
          >
            <slot />
          </div>
          <div class={{ 'hidden-button': this.isMobile, 'ptz-carousel-buttons': true }}>
            <button class={classArrowButton('left')} disabled={this.disabledLeftButton} aria-label="Seta para a esquerda" onClick={event => this.handlePreviousSlide(event)}>
              <slot name="arrow-left" />
            </button>
            <button class={classArrowButton('right')} disabled={this.disabledRightButton} aria-label="Seta para a direita" onClick={event => this.handleNextSlide(event)}>
              <slot name="arrow-right" />
            </button>
          </div>
        </div>
      </Host>
    );
  }
}
