ptz-selector {
  &:not(.ptz-selector-read-only) {
    display: flex;
    padding: $petz-spacing-2xs;
    padding-right: 0;
  }

  button {
    all: unset;
  }

  .ptz-selector {
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    position: relative;
    padding: 0 $petz-spacing-xs;
    justify-content: center;
    align-items: center;
    gap: $petz-spacing-2xs;
    color: $petz-color-neutral-darker-accent;
    width: max-content;
    text-align: center;
    font-style: normal;
    font-weight: $petz-font-weight-bold;
    line-height: $petz-font-lineheight-lg;
    border-radius: $petz-rounded-xs;
    border: $petz-border-sm solid $petz-color-neutral-mid;

    &:hover {
      border: $petz-border-sm solid $petz-color-neutral-black;
    }

    &-md {
      height: $petz-spacing-lg;
      max-height: $petz-spacing-lg;
    }

    &-sm {
      height: $petz-spacing-md;
      max-height: $petz-spacing-md;
    }

    &-sm, &-md {
      font-size: $petz-font-size-2xs;
      max-height: $petz-spacing-lg;

      &-sale, &-unavailable {
        &-icon {
          top: -$petz-spacing-2xs;
          left: -$petz-spacing-2xs;
          width: $petz-spacing-sm;
          height: $petz-spacing-sm;
        }
      }

      &-support-text {
        display: none;
      }
    }

    &-lg {
      width: 90px;
      height: $petz-spacing-3xl;
      max-height: $petz-spacing-3xl;
      font-size: $petz-font-size-xs;

      &-sale, &-unavailable, &-combo-top {
        &-icon {
          width: 30.4px;
          height: 30.4px;
          top: -$petz-spacing-sm;
          left: -$petz-spacing-sm;
        }
      }

      &-support-text {
        width: 100%;
        display: flex;
        bottom: -21px;
        position: absolute;
        justify-content: center;
        color: $petz-color-neutral-black;
        font-size: $petz-font-size-2xs;
        font-weight: $petz-font-weight-bold;
        line-height: $petz-font-lineheight-lg;

        &:first-child {
          display: flex;
        }
      }
    }

    &-sale, &-unavailable, &-combo-top {
      &-icon {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        border-radius: $petz-rounded-max;
        margin-right: $petz-spacing-2xs;
        border: $petz-border-sm solid $petz-color-neutral-light;
        background-color: $petz-color-neutral-white;
      }
    }

    &-unavailable {
      color: $petz-color-neutral-mid;
      border: $petz-border-sm solid $petz-color-neutral-light;
    }

    &-combo {
      .ptz-selector-wrapper {
        display: flex;
        align-items: flex-end;
      }

      &-icon {
        display: flex;
        width: 18px;
        height: 18px;
        justify-content: center;
        align-items: center;
        margin-right: $petz-spacing-2xs;
      }

      &-top-icon {
        border: $petz-border-sm solid $petz-color-sup-green-dark;
        background-color: $petz-color-sup-green-light;
      }
    }

    &-selected {
      color: $petz-color-neutral-white;
      background-color: $petz-color-neutral-black;
      border: $petz-border-sm solid $petz-color-neutral-black;
    }

    &-view {
      cursor: default;
      color: $petz-color-neutral-dark;
      background: $petz-color-neutral-lighter-accent;
      border: $petz-border-sm solid $petz-color-neutral-lighter-accent;

      &:hover {
        border: $petz-border-sm solid $petz-color-neutral-lighter-accent;
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-md) {
  ptz-selector {
    &:not(.ptz-selector-read-only) {
      padding-top: 2px;
      padding-bottom: 6px;
    }
  }
}
